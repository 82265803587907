import {FC} from "react"
import {Trans} from "react-i18next"

import {AddModelNumberModal} from "@frontend/components/add-model-number-modal"
import {trackEvent} from "@frontend/utils/trackEvent"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import type {UiCase} from "@ri2/app/server/routers/getCasePageData"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

interface Props {
  caseModel: UiCase
}

export const CaseHeader: FC<Props> = ({
  caseModel: {id, name, modelDescription, modelNumber},
}) => {
  const {
    setTrue: onShowAddModelNumberModal,
    setFalse: onHideAddModelNumberModal,
    state: showAddModelNumberModal,
  } = useBooleanState(false)

  const onClickShowModelNumberModal = (): void => {
    onShowAddModelNumberModal()
    trackEvent("chat_model_no_tip")
  }

  return (
    <>
      <div
        className={vstack({
          alignItems: {
            base: "center",
            desktop: "flex-start",
          },
          gap: 0,
        })}
      >
        <div
          className={css({
            textStyle: "bodyLargeMedium",
            color: "text.primary",
            lineClamp: 1,
            textAlign: "center",

            desktop: {
              textAlign: "left",
            },
          })}
        >
          {name}
        </div>
        <div
          className={css({
            textStyle: "captionRegular",
            lineClamp: 1,
            textAlign: "center",

            desktop: {
              textAlign: "left",
            },
          })}
        >
          <span
            className={css({
              color: "text.quaternary",
            })}
          >
            {modelDescription} {"/"}
          </span>{" "}
          {modelNumber && (
            <span
              className={css({
                color: "text.brand.accent",
                textStyle: "captionMedium",
              })}
            >
              {modelNumber}
            </span>
          )}
          {!modelNumber && (
            <span
              className={css({
                color: "text.quaternary",
              })}
            >
              <Trans i18nKey="appContainer.header.noModelNumber">
                <button
                  onClick={onClickShowModelNumberModal}
                  className={css({
                    color: "text.brand.accent",
                    textStyle: "captionMedium",
                  })}
                />
              </Trans>
            </span>
          )}
        </div>
      </div>
      {showAddModelNumberModal && (
        <AddModelNumberModal caseId={id} onClose={onHideAddModelNumberModal} />
      )}
    </>
  )
}
