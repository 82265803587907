import {FC, useEffect, useState} from "react"

import {useTranslation} from "@frontend/i18n"
import {Peek as PeekModel} from "@ri2/app/server/routers/getPeek"
import {UiCauses} from "@ri2/app/server/routers/getUiCauses"
import {Case, Cost, PriceRanges} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

import {CausesColumn} from "./CausesColumn"
import {CausesHeader} from "./CausesHeader"
import {Peek} from "./peek"

const EMPTY_COST: Cost = {cost: 0, source: {type: "match"}}

interface Props {
  caseModel: Case
  causes: UiCauses
  peek: PeekModel | null
  className?: string
}

export const Causes: FC<Props> = ({caseModel, causes, peek, className}) => {
  // Store the last peek so that if it becomes null, we can
  // render the old values as it animates out.
  const [lastPriceRanges, setLastPriceRanges] = useState<PriceRanges>(
    peek?.priceRanges ?? {
      diyCost: EMPTY_COST,
      difmCost: EMPTY_COST,
      replaceCost: EMPTY_COST,
    },
  )

  useEffect(() => {
    if (peek) {
      setLastPriceRanges(peek.priceRanges)
    }
  }, [peek])

  const t = useTranslation()

  const hasConfirmed = causes.confirmed.length > 0

  return (
    <div
      role="complementary"
      aria-label={t("causes.ariaLabel")}
      className={cx(
        css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowY: "auto",
          position: {base: "unset", desktop: "relative"},
          zIndex: 0, // to avoid children appearing on top of the sidebar
          paddingX: 32,
          desktop: {
            paddingRight: 24,
            paddingLeft: 4,
          },
          largeDesktop: {
            paddingLeft: 8,
            paddingRight: 25,
          },
          paddingTop: {
            base: 24,
            desktop: 0,
          },
          maxHeight: {
            base: "101%",
            desktop: "calc(100vh - 128px)",
          },
          desktopDown: {
            backgroundColor: "background.secondary",
          },
        }),
        className,
      )}
    >
      <div>
        <div
          className={css({
            marginTop: {
              desktop: 40,
            },
          })}
        >
          <h2
            className={css({
              fontWeight: 300,
              fontSize: 24,
              lineHeight: "30px",
              color: "text.primary",
              letterSpacing: "-0.01em",
              marginBottom: 24,
              display: {
                base: "none",
                desktop: "block",
              },
            })}
          >
            {t("cases.case.nextStepsTitle")}
          </h2>
          <CausesHeader caseModel={caseModel} hasConfirmed={hasConfirmed} />
        </div>
        <CausesColumn caseModel={caseModel} causes={causes} />
      </div>
      {!!peek && (
        <Peek
          caseId={caseModel.id}
          priceRanges={peek.priceRanges ?? lastPriceRanges}
          recommendation={peek.recommendation ?? null}
          className={css({
            position: "sticky",
            bottom: 0,
            zIndex: "causes.peek",
            transition: "0.3s ease",
            opacity: peek ? 1 : 0,
          })}
        />
      )}
    </div>
  )
}
