import {FC} from "react"
import {Trans} from "react-i18next"

import {useTranslation} from "@frontend/i18n"
import {Case} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

interface Props {
  caseModel: Case
  hasConfirmed: boolean
}

export const CausesHeader: FC<Props> = ({caseModel, hasConfirmed}) => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingY: 5,
        gap: 4,
        alignItems: "flex-start",
      })}
    >
      <h2
        className={css({
          fontSize: 32,
          lineHeight: "36px",
          fontWeight: 300,
          color: "text.primary",
          letterSpacing: "-0.02em",
        })}
      >
        <Trans
          i18nKey={
            hasConfirmed
              ? "cases.case.confirmedHeader"
              : caseModel.messages.some(
                    (message) => message.mode === "ASK_DIAGNOSTIC_QUESTION",
                  )
                ? "cases.case.keepChatting"
                : "cases.case.initialQuestions"
          }
        >
          <span className={css({color: "text.brand.accent"})} />
        </Trans>
      </h2>
      <p
        className={css({
          textStyle: "bodyLight",
          color: "text.tertiary",
        })}
      >
        {hasConfirmed
          ? t("cases.case.confirmedReason")
          : t("cases.case.keepChattingReason")}
      </p>
    </div>
  )
}
