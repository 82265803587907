import {Dialog} from "@ark-ui/react"
import {FC} from "react"
import {Trans} from "react-i18next"

import {CloseButton} from "@frontend/components/modal/close-button"
import age from "@frontend/design/icons/age.svg"
import cost from "@frontend/design/icons/currency-circle-dollar.svg"
import skills from "@frontend/design/icons/skills.svg"
import time from "@frontend/design/icons/time.svg"
import {useTranslation} from "@frontend/i18n"
import {useCloseOnEsc} from "@frontend/utils/useCloseOnEsc"
import {Recommendation} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Reason} from "./reason"
import {ConsiderationItem} from "../consideration-item"

interface Props {
  recommendation: Recommendation
  show: boolean
  onDismiss: () => void
}

export const LearnMoreBasedOnModal: FC<Props> = ({
  onDismiss,
  show,
  recommendation: {reasons, type},
}) => {
  const t = useTranslation()

  useCloseOnEsc(onDismiss)

  const considerationItems = [
    {
      icon: cost,
      name: t("cause.considerations.cost.title"),
      content: t("cause.considerations.cost.description"),
    },
    {
      icon: skills,
      name: t("cause.considerations.difficulty.title"),
      content: t("cause.considerations.difficulty.description"),
    },
    {
      icon: age,
      name: t("cause.considerations.age.title"),
      content: t("cause.considerations.age.description"),
    },
    {
      icon: time,
      name: t("cause.considerations.convenience.title"),
      content: t("cause.considerations.convenience.description"),
    },
  ]

  return (
    <Dialog.Root
      open={show}
      closeOnEscape={false}
      closeOnInteractOutside={false}
      lazyMount
      onOpenChange={({open}) => {
        if (!open) {
          onDismiss()
        }
      }}
    >
      <Dialog.Backdrop
        className={css({
          position: "fixed",
          width: "100%",
          height: "100%",
          inset: 0,
          backgroundColor: "#000000",
          opacity: 0.5,
          zIndex: "modalBackdrop",
        })}
      />
      <Dialog.Positioner
        onClick={onDismiss}
        className={css({
          position: "fixed",
          inset: 0,
          zIndex: "modal",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <Dialog.Content
          onClick={(e) => e.stopPropagation()}
          className={css({
            backgroundColor: "white",
            boxShadow: "0px 9px 20px 0px #1C254824",
            width: "min(100%, 601px)",
            display: show ? "flex" : undefined,
            flexDirection: "column",
            maxHeight: "100%",
            padding: 20,
            minWidth: 0,
            borderRadius: "12px",
            marginX: "16px",
            overflowY: "auto",
          })}
        >
          <div
            className={css({
              width: "100%",
            })}
          >
            <div
              className={hstack({
                justifyContent: "space-between",
                alignItems: "center",
                minHeight: 44,
              })}
            >
              <h3
                className={css({
                  textStyle: "header.xs",
                  color: "text.primary",
                })}
              >
                <Trans i18nKey={`cause.weRecommend.${type}`}>
                  <span
                    className={cx(
                      css({
                        color: "text.brand.accent",
                      }),
                    )}
                  />
                </Trans>
              </h3>
              <CloseButton onClick={onDismiss} />
            </div>
            <div
              className={hstack({
                width: "100%",
                paddingBottom: 8,
                height: 36,
                gap: 8,
                flexWrap: "wrap",
              })}
            >
              <p
                className={css({
                  textStyle: "captionRegular",
                  color: "text.tertiary",
                })}
              >
                {t("cause.basedOn")}
              </p>

              {reasons.map((reason) => (
                <Reason key={reason} reason={reason} />
              ))}
            </div>
          </div>
          <p
            className={css({
              marginTop: 36,
              marginBottom: 8,
              textStyle: "bodyMedium",
              color: "text.tertiary",
            })}
          >
            {t("cause.factorsConsideration")}
          </p>
          <div
            className={hstack({
              flexWrap: "wrap",
              width: "100%",
              gap: 8,
              alignItems: "flex-start",
            })}
          >
            {considerationItems.map((item) => (
              <div
                className={css({
                  width: "100%",
                  desktop: {
                    minWidth: 276,
                    maxWidth: 276,
                  },
                })}
                key={item.name}
              >
                <ConsiderationItem
                  icon={item.icon}
                  name={item.name}
                  content={item.content}
                />
              </div>
            ))}
          </div>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  )
}
