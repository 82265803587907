import {uniqBy} from "lodash"
import {FC} from "react"

import {ReactionsWithForm} from "@frontend/components"
import thinkingAnimation from "@frontend/design/animations/thinking.svg"
import {useTranslation} from "@frontend/i18n"
import {
  ASK_FOR_MODEL_NUMBER,
  type Message as MessageModel,
  getConfirmedCausesFromMessage,
  getContentFromMessage,
} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

import {Cause} from "./causes/cause"
import {EventLayout} from "./event-layout"
import {MessageContent} from "./message-content"

interface Props {
  caseId: string
  className?: string
  message: MessageModel
  previouslyConfirmedCauseIds: string[]
  isReceivingMessage: boolean
  receivingMessageStatus: string | null
  isMostRecentMessage: boolean
  onSelectMedia: (url: string) => void
}

export const Message: FC<Props> = (props) => {
  const mode = getModeFromProps(props)
  const t = useTranslation()

  const text = getTextFromMode(mode, t)

  const isThinkingOrAI = props.message.type === "ai"

  const questionMedia =
    props.message.type === "ai"
      ? uniqBy(
          [
            ...(props.message.content.questionImages ?? []),
            ...(props.message.content.questionVideos ?? []),
            ...(props.message.content.partTestingVideos ?? []),
          ],
          (element) => element.url,
        )
      : []
  const disassemblyVideos =
    props.message.type === "ai"
      ? uniqBy(
          props.message.content.disassemblyVideos,
          (element) => element.url,
        ) ?? []
      : []
  const partImages =
    props.message.type === "ai" ? props.message.content.partImages : undefined

  const timestamp = props.message.createdAt

  const newlyConfirmedCauses = getConfirmedCausesFromMessage(
    props.message,
  ).filter(({causeId}) => !props.previouslyConfirmedCauseIds.includes(causeId))

  const isAskModelNumberMessage =
    props.message.type === "ai" && props.message.mode === ASK_FOR_MODEL_NUMBER

  return (
    <EventLayout
      isThinkingOrAI={isThinkingOrAI}
      timestamp={timestamp}
      className={props.className}
    >
      <div
        className={css({
          width: "100%",
          maxWidth: 570,
          display: "flex",
          minWidth: 0,
          flexDirection: "column",
          gap: 12,
          color: "text.primary",
          textStyle: "body",
          fontSize: {base: isThinkingOrAI ? 18 : 16, desktop: 16},
        })}
      >
        {text && (
          <div
            className={vstack({
              alignItems: "stretch",
            })}
          >
            <MessageContent
              isThinkingOrAi={isThinkingOrAI}
              message={text}
              questionMedia={questionMedia}
              disassemblyVideos={disassemblyVideos}
              partImages={partImages}
              onSelectMedia={props.onSelectMedia}
              isAskModelNumberMessage={isAskModelNumberMessage}
            />
          </div>
        )}
        {!!props.receivingMessageStatus && !text && (
          <div className={css({display: "flex"})}>
            <p className={css({marginRight: 2, fontWeight: "500"})}>
              {props.receivingMessageStatus}
            </p>
            <img src={thinkingAnimation} height={15} width={15} />
          </div>
        )}

        {props.message.type === "ai" && !props.isReceivingMessage && (
          <>
            {newlyConfirmedCauses.length > 0 && (
              <div
                className={vstack({
                  gap: 20,
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  marginTop: 20,
                })}
              >
                {newlyConfirmedCauses.map((cause) => (
                  <Cause
                    caseId={props.caseId}
                    cause={cause}
                    key={cause.id}
                    bucket="confirmed"
                    setHoveredItem={() => {}}
                    iconBackground="colors.confirmedIconsBackground.400"
                    isHighlighted
                    isInConversation
                  />
                ))}
              </div>
            )}
            <ReactionsWithForm
              on={{
                on: "message",
                messageId: props.message.id,
              }}
              tooltipVariant="dark"
              isInline
              tooltipPlacement={props.isMostRecentMessage ? "top" : "bottom"}
              currentFeedback={props.message.feedback}
              transparent
              // z index needed to make sure the tooltip is above the cause card if one
              className={css({marginTop: 8, zIndex: 1})}
            />
          </>
        )}
      </div>
    </EventLayout>
  )
}

type Mode =
  | {
      mode: "message"
      message: MessageModel
      onChooseMultipleChoiceOption?: (option: string) => void
    }
  | {
      mode: "thinking"
    }

const getModeFromProps = (props: Props): Mode =>
  "message" in props
    ? {
        mode: "message",
        message: props.message,
      }
    : {mode: "thinking"}

const getTextFromMode = (
  mode: Mode,
  t: ReturnType<typeof useTranslation>,
): string | undefined => {
  switch (mode.mode) {
    case "message":
      return getContentFromMessage(mode.message)
    case "thinking":
      return t("conversation.thinking")
  }
}
