import {useEffect, useRef, useState} from "react"

import {API_BASE_URL} from "@frontend/env"
import {useTranslation} from "@frontend/i18n"
import {parseStream} from "@frontend/utils/parseStream"
import {invalidateCasePageQueries, trpc} from "@frontend/utils/trpc"
import {useTick} from "@frontend/utils/useTick"
import type {AddHumanMessageToCaseParams} from "@ri2/app/app/api/addHumanMessageToCase/route"
import {Case, Message, getCausesFromMessages} from "@ri2/db/client"

const OPTIMISTIC_HUMAN_MESSAGE_ID = "optimistic-human-message"
export const OPTIMISTIC_AI_MESSAGE_ID = "optimistic-ai-message"

const MESSAGE_ERROR_INDICATOR = "\u2302"

export const useSendMessage = (
  userRcId: string,
  caseModel: Case,
): {
  sendMessage: (message: string) => void
  abortSendMessage: () => void
  isReceivingMessage: boolean
  receivingMessageStatus: string | null
} => {
  const t = useTranslation()

  const messageIds = caseModel.messages.map(({id}) => id)
  const isReceivingMessage =
    messageIds.includes(OPTIMISTIC_HUMAN_MESSAGE_ID) ||
    messageIds.includes(OPTIMISTIC_AI_MESSAGE_ID)

  const [timeOfLastStatusUpdate, setTimeOfLastStatusUpdate] = useState<
    number | null
  >(null)
  const [receivingMessageStatus, internalSetReceivingMessageStatus] = useState<
    string | null
  >(null)
  const [stream, setStream] = useState<string>("")

  const setReceivingMessageStatus = (newStatus: string | null): void => {
    if (newStatus) {
      internalSetReceivingMessageStatus(newStatus)
      setTimeOfLastStatusUpdate(getNow())
    } else {
      internalSetReceivingMessageStatus(newStatus)
      setTimeOfLastStatusUpdate(null)
    }
  }

  useTick(!!receivingMessageStatus)

  const lastMessageIdRef = useRef<string | undefined>()

  const utils = trpc.useUtils()

  const [errored, setErrored] = useState(false)

  // If an error was thrown in the sendMessage promise, throw it to
  // the error boundary.
  if (errored) {
    throw new Error("Error sending message")
  }

  const [{abortSendMessage}, setAbortSendMessage] = useState({
    abortSendMessage: () => {},
  })

  const sendMessage = async (message: string): Promise<void> => {
    if (isReceivingMessage) {
      console.error("Tried to send a message while receiving a message")
      setErrored(true)
      return
    }

    const lastMessageId = caseModel.messages[caseModel.messages.length - 1]?.id
    lastMessageIdRef.current = lastMessageId

    setReceivingMessageStatus(t("cases.case.analyzingMessage"))

    const optimisticHumanMessage: Message = {
      id: OPTIMISTIC_HUMAN_MESSAGE_ID,
      caseId: caseModel.id,
      createdAt: new Date(),
      type: "human",
      mode: null,
      basedOnMessageId: null,
      content: {
        content: message,
        answers: new Map(),
        isMultipleChoiceAnswer: false,
      },
    }

    const optimisticAiMessage: Message = {
      id: OPTIMISTIC_AI_MESSAGE_ID,
      caseId: caseModel.id,
      mode: null,
      basedOnMessageId: null,
      // date slightly in the future to guarantee it sorts after the
      // optimistic human message
      createdAt: new Date(Date.now() + 1),
      type: "ai",
      content: {question: ""},
      causes: getCausesFromMessages(caseModel.messages),
    }

    utils.casePageData.setData({id: caseModel.id}, (cached) => {
      if (!cached) return cached

      const {caseModel, ...old} = cached

      return {
        ...old,
        caseModel: {
          ...caseModel,
          messages: [
            ...caseModel.messages,
            optimisticHumanMessage,
            optimisticAiMessage,
          ],
        },
      }
    })

    const abortController = new AbortController()

    setAbortSendMessage({
      abortSendMessage: () => {
        // `abort()` must be called with `abortController` as `this`
        abortController.abort()
      },
    })

    const params: AddHumanMessageToCaseParams = {
      userRcId,
      caseId: caseModel.id,
      message,
    }

    const reload = (): void => {
      invalidateCasePageQueries(utils, {
        userRcId,
        caseId: caseModel.id,
      })
    }

    try {
      const response = await fetch(`${API_BASE_URL}/addHumanMessageToCase`, {
        method: "POST",
        headers: {
          "Accept-Encoding": "identity",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
        signal: abortController.signal,
      })

      const reader = response.body!.getReader()
      const decoder = new TextDecoder()

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {done, value} = await reader.read()

        if (done) {
          reload()
          break
        }

        const chunk = decoder.decode(value)

        if (chunk.includes(MESSAGE_ERROR_INDICATOR)) {
          throw new Error("Received error indicator from backend")
        }

        setStream((r) => r + chunk)
      }

      reader.cancel()
    } catch (error) {
      if (error instanceof DOMException && error.name === "AbortError") {
        console.warn("Got abort error, presumably because user aborted", error)
      } else {
        console.error("Error sending message", error)

        setErrored(true)
      }
    } finally {
      setStream("")

      if (lastMessageId === lastMessageIdRef.current) {
        reload()
      }
    }
  }

  useEffect(() => {
    if (!stream) return

    const pendingStatus = parseStream(stream)

    if (pendingStatus && pendingStatus !== receivingMessageStatus) {
      setReceivingMessageStatus(pendingStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream])

  useEffect(() => {
    if (receivingMessageStatus && !isReceivingMessage) {
      setReceivingMessageStatus(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReceivingMessage])

  const numSecondsBeforeStale = 10

  const isStatusStale =
    timeOfLastStatusUpdate &&
    getNow() - timeOfLastStatusUpdate > numSecondsBeforeStale

  const upToDateStatus =
    receivingMessageStatus && isStatusStale
      ? t("conversation.stillStatus", {
          status: receivingMessageStatus.toLowerCase(),
        })
      : receivingMessageStatus

  return {
    sendMessage,
    abortSendMessage,
    isReceivingMessage,
    receivingMessageStatus: upToDateStatus,
  }
}

const getNow = (): number => Math.floor(Date.now() / 1000)
