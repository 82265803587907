export const addItemsToCart = (
  items: {
    id: number
    oem_number: string
    name?: string
    model_number: string
    price?: number
  }[],
  caseId: string,
): void => window!.ri2!.addItemsToCart(items, caseId)
