import type {Part as PartModel} from "@ri2/rc-db"
import {FC} from "react"
import {useSearchParams} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import infoCircleIcon from "@frontend/design/icons/info-circle.svg"
import missingPartImage from "@frontend/design/icons/missing-part-image.png"
import missingPartIcon from "@frontend/design/icons/missing-part.svg"
import {useTranslation} from "@frontend/i18n"
import {icons} from "@frontend/routes/cases/[caseId]/components/causes/icons"
import {useSession} from "@frontend/session"
import {addItemsToCart} from "@frontend/utils/appHostFunctions"
import {getImageUrl} from "@frontend/utils/getImageUrl"
import {useAddToCartMutation} from "@frontend/utils/trpc"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

interface Props {
  caseId: string
  part: PartModel
  mode: "single" | "list"
  partsIcon?: string
  className?: string
}

export const Part: FC<Props> = ({
  caseId,
  part: {id, image, price, manufacturerPartNumber, name, purchaseUrl},
  mode,
  className,
  partsIcon,
}) => {
  const t = useTranslation()

  const {userRcId} = useSession()
  const addToCartMutation = useAddToCartMutation()

  const onAddToCart = (): void => {
    addItemsToCart(
      [
        {
          id,
          oem_number: manufacturerPartNumber,
          name: name ?? undefined,
          model_number: manufacturerPartNumber,
          price: price != null ? price / 100 : undefined,
        },
      ],
      caseId,
    )

    addToCartMutation.mutate({
      userRcId,
      caseId,
      partId: id,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  const onLearnMore = purchaseUrl
    ? (): void => {
        setSearchParams({
          id: id.toString(),
          oem_number: manufacturerPartNumber,
          name: name ?? "",
          model_number: manufacturerPartNumber,
          price: price != null ? (price / 100).toString() : "",
        })

        window.open(purchaseUrl, "_blank")
      }
    : null

  return (
    <div
      className={cx(
        mode === "single"
          ? vstack({
              gap: 8,
              alignItems: "stretch",
              paddingY: 16,
            })
          : hstack({
              borderBottomColor: "rgba(255, 255, 255, 0.1)",
              paddingY: 16,
              gap: 12,
              alignItems: "center",
            }),
        className,
      )}
    >
      <div
        className={css({
          position: "relative",
        })}
      >
        <img
          src={image?.url ? getImageUrl(image.url) : missingPartImage}
          alt=""
          className={cx(
            css({
              objectFit: "cover",
              borderRadius: 16,
            }),
            mode === "single"
              ? css({width: "100%", aspectRatio: "274/184"})
              : css({height: 88, width: 88}),
          )}
        />
        {!image?.url && (
          <ReactSVG
            src={partsIcon ? icons[partsIcon] : missingPartIcon}
            className={cx(
              iconsColor({color: "foreground.brand.primary"}),
              css({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                zIndex: 2,
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                width: 72,
                height: 72,
                borderRadius: "50%",
              }),
            )}
          />
        )}
      </div>
      <div
        className={vstack({
          flexGrow: 1,
          gap: 8,
          alignItems: "stretch",
        })}
      >
        <p
          className={cx(
            css({
              color: "white",
              lineClamp: 3,
            }),
            mode === "single"
              ? css({
                  textStyle: "bodyRegular",
                })
              : css({
                  textStyle: "captionRegular",
                }),
          )}
        >
          {name}
        </p>
        <div
          className={hstack({
            gap: 8,
            width: "100%",
          })}
        >
          <Button
            variant="primary"
            onClick={onAddToCart}
            title={t("cause.diy.materials.addToCart") as string}
            rightElement={<span>{t("general.price", {price})}</span>}
            css={css.raw({
              minWidth: "50%",
              justifyContent: "space-between",
            })}
            size="large"
          />

          {onLearnMore && (
            <Button
              variant="secondaryOnBrand"
              size="large"
              leftElement={mode === "list" && <ReactSVG src={infoCircleIcon} />}
              title={
                mode === "single" ? (t("cause.diy.parts.learn") as string) : ""
              }
              onClick={onLearnMore}
              css={
                mode === "list" &&
                css.raw({
                  paddingX: 8,
                  paddingY: 8,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
