import {Dialog, Portal} from "@ark-ui/react"
import {FC, useEffect, useRef, useState} from "react"

import {getAppRoot} from "@frontend/utils/getAppRoot"
import {useCloseOnEsc} from "@frontend/utils/useCloseOnEsc"
import {MessageMedia, isMessageVideo} from "@ri2/db/message/client"
import {css, cx} from "@styled-system/css"

import {Content} from "./content"
import {Header} from "./header"
import {MobileFooter} from "./mobile-footer"
import {useMultimediaSlider} from "./useMultimediaSlider"

interface Props {
  show: boolean
  onDismiss: () => void
  title: string
  media: MessageMedia[]
  initialImageIndex: number
}

export const MediaGallery: FC<Props> = ({
  show,
  onDismiss,
  title,
  media,
  initialImageIndex,
}) => {
  const {currentMediaIndex, nextMedia, previousMedia} = useMultimediaSlider(
    media,
    initialImageIndex,
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHasRendered] = useState(false)
  useEffect(() => {
    setHasRendered(true)
  }, [])

  const appRoot = getAppRoot()
  const appRootRef = useRef(appRoot)
  appRootRef.current = appRoot
  useCloseOnEsc(onDismiss)

  if (!appRoot) {
    return
  }

  const mediaUrl = media[currentMediaIndex].url

  const isImage = !isMessageVideo(media[currentMediaIndex])

  return (
    <Dialog.Root
      open={show}
      closeOnEscape={false}
      closeOnInteractOutside={false}
      lazyMount
      onOpenChange={({open}) => {
        if (!open) {
          onDismiss()
        }
      }}
    >
      <Portal container={appRootRef}>
        <Dialog.Backdrop
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              backgroundColor: {
                base: "transparent",
                desktop: "#000000",
              },
              opacity: {
                base: 1,
                desktop: 0.5,
              },
              backdropFilter: "blur(12px)",
              zIndex: "modalBackdrop",
            }),
          )}
        />
        <Dialog.Positioner
          onClick={onDismiss}
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              zIndex: "modal",
              display: "flex",
              justifyContent: "center",
            }),
          )}
        >
          <Dialog.Content
            onClick={(e) => e.stopPropagation()}
            className={cx(
              css({
                backgroundColor: "rgba(0, 0, 0, 0.90)",
                backdropFilter: "blur(12px)",
                width: "100%",
                display: show ? "flex" : undefined,
                flexDirection: "column",
                textStyle: "body",
                maxHeight: "100%",
                borderRadius: 0,
                paddingTop: 16,
                paddingBottom: 16,
                minWidth: 0,
                desktop: {
                  marginX: 20,
                  marginY: 20,
                  paddingBottom: 0,
                  paddingTop: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.70)",
                  width: undefined,
                  borderRadius: 12,
                  overflowY: "hidden",
                },
              }),
            )}
          >
            <Header
              title={title}
              onDismiss={onDismiss}
              mediaUrl={mediaUrl}
              isImage={isImage}
            />
            <Content
              mediaItems={media}
              currentMediaIndex={currentMediaIndex}
              onShowNextMedia={nextMedia}
              onShowpreviousImage={previousMedia}
              isOpen={show}
            />
            <MobileFooter mediaUrl={mediaUrl} isImage={isImage} />
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
