// Keeping this code out of the main component avoid rerenders on the confirmed causes list
import {omitBy} from "lodash"
import {FC} from "react"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import {useTranslation} from "@frontend/i18n"
import {UiCauses} from "@ri2/app/server/routers/getUiCauses"
import {Case} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {CausesList} from "./CausesList"

interface Props {
  caseModel: Case
  causes: UiCauses
}

export const CausesColumn: FC<Props> = ({caseModel, causes}) => {
  const t = useTranslation()

  const colorVariant = "informational" as const

  const buckets = omitBy(causes, (list) => list.length === 0)
  const noCauses = Object.keys(buckets).length === 0

  return (
    <div className={css({marginTop: 49})}>
      {noCauses ? (
        <InformationBanner
          content={t(`cases.case.noOtherCausesMessage.possible`)}
          variant={colorVariant}
        />
      ) : (
        <>
          {Object.keys(buckets).map((bucket) => {
            const typedBucket = bucket as keyof typeof buckets
            return (
              <CausesList
                key={bucket}
                bucket={typedBucket}
                causes={buckets[typedBucket]!}
                caseId={caseModel.id}
              />
            )
          })}
        </>
      )}
    </div>
  )
}
