import {FC} from "react"
import {ReactSVG} from "react-svg"

import check from "@frontend/design/icons/check.svg"
import linkIcon from "@frontend/design/icons/link.svg"
import {useTranslation} from "@frontend/i18n"
import {trackEvent} from "@frontend/utils/trackEvent"
import {useCopyText} from "@frontend/utils/useCopyText"
import {css} from "@styled-system/css"
import {hstack, iconsColor} from "@styled-system/patterns"

export const CopyLink: FC = () => {
  const t = useTranslation()

  const url = window.location.href
  const [isCopied, clickCopy] = useCopyText(url)

  const onClickToCopy = (): void => {
    clickCopy()
    trackEvent("chat_save_case")
  }

  return (
    <div
      className={css({
        paddingX: 8,
        width: "100%",
        marginBottom: 4,
      })}
    >
      <div
        className={hstack({
          paddingBottom: 8,
          borderBottomWidth: 1,
          justifyContent: "flex-start",
          borderColor: "border.brand.primary",
        })}
      >
        <p
          className={css({
            textStyle: "bodyLight",
            color: "text.tertiary",
          })}
        >
          {t("conversation.caseLink.message")}
        </p>
        <button
          onClick={onClickToCopy}
          className={hstack({
            color: "text.brand.secondary",
            backgroundColor: "background.brand.primarySubtle",
            padding: 10,
            borderRadius: 999,
            justifyContent: "space-between",
            gap: 4,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "border.brand.primary",
            textStyle: "captionMedium",
            cursor: "pointer",
          })}
        >
          <ReactSVG
            src={isCopied ? check : linkIcon}
            className={iconsColor({color: "foreground.brand.secondary"})}
          />
          {isCopied ? t("case.copied") : t("case.copyLink")}
        </button>
      </div>
    </div>
  )
}
