import {Dialog, Portal} from "@ark-ui/react"
import {FC, PropsWithChildren, useEffect, useRef, useState} from "react"

import {getAppRoot} from "@frontend/utils/getAppRoot"
import {Styles, css, cx} from "@styled-system/css"

interface Props {
  show: boolean
  onDismiss: () => void
  width?: number
  bottomSheet?: boolean
  containerCss?: Styles
}

export const BaseModal: FC<PropsWithChildren<Props>> = ({
  show,
  onDismiss,
  width,
  bottomSheet,
  children,
  containerCss,
}) => {
  // Change some state to cause one extra render on mount in case the
  // app root wasn't available yet.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHasRendered] = useState(false)
  useEffect(() => {
    setHasRendered(true)
  }, [])

  const appRoot = getAppRoot()
  const appRootRef = useRef(appRoot)
  appRootRef.current = appRoot

  if (!appRoot) {
    return
  }

  return (
    <Dialog.Root
      open={show}
      closeOnEscape={false}
      closeOnInteractOutside={false}
      lazyMount
      onOpenChange={({open}) => {
        if (!open) {
          onDismiss()
        }
      }}
    >
      <Portal container={appRootRef}>
        <Dialog.Backdrop
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              backgroundColor: "#000000",
              opacity: 0.5,
              zIndex: "modalBackdrop",
            }),
            bottomSheet &&
              css({
                _open: {
                  animation: {
                    base: "fadeInOverlay 0.2s",
                    desktop: "unset",
                  },
                },
                _closed: {
                  animation: {
                    base: "fadeOutOverlay 0.2s",
                    desktop: "unset",
                  },
                },
              }),
          )}
        />
        <Dialog.Positioner
          onClick={onDismiss}
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              zIndex: "modal",
              display: "flex",
              justifyContent: "center",
            }),
            bottomSheet
              ? css({alignItems: {base: "flex-end", desktop: "center"}})
              : css({alignItems: "center"}),
          )}
        >
          <Dialog.Content
            onClick={(e) => e.stopPropagation()}
            className={cx(
              css(
                {
                  width: {base: "100%", desktop: undefined},
                  display: show ? "flex" : undefined,
                  flexDirection: "column",
                  textStyle: "body",
                  backgroundColor: "white",
                  maxHeight: "100%",
                  paddingTop: {
                    base: "modalPaddingMobile",
                    desktop: "modalPaddingDesktop",
                  },
                  paddingBottom: {
                    base: "modalPaddingMobile",
                    desktop: "modalPaddingDesktop",
                  },
                  minWidth: 0,
                  overflow: "hidden",
                },
                bottomSheet
                  ? {
                      borderTopRadius: 12,
                      desktop: {
                        borderBottomRadius: 12,
                      },
                      _open: {
                        animation: {
                          base: "slideFromBottom 0.2s",
                          desktop: "unset",
                        },
                      },
                      _closed: {
                        animation: {
                          base: "slideFromTop 0.2s",
                          desktop: "unset",
                        },
                      },
                    }
                  : {borderRadius: 12, marginX: 16},
                containerCss,
              ),
            )}
            style={{width}}
          >
            {children}
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
