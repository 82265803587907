import {last, throttle} from "lodash"
import {FC, useCallback, useEffect, useRef, useState} from "react"

import {useTranslation} from "@frontend/i18n"
import {trackEvent} from "@frontend/utils/trackEvent"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"
import {
  type CaseEvent,
  MessageMedia,
  findLastMap,
  getMediaFromMessage,
  isCauseOnMessageConfirmed,
  isMessageVideo,
} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {ConversationActions} from "./ConversationActions"
import {Message} from "./Message"
import {ConversationInputField} from "./conversation-input-field"
import {CopyLink} from "./copy-link"
import {messageContainerClass} from "./event-layout"
import {MediaGallery} from "./media-gallery"
import {ResolveEvent} from "./resolve-event"
import {OPTIMISTIC_AI_MESSAGE_ID} from "../util/useSendMessage"

interface Props {
  caseId: string
  resolved: boolean
  events: CaseEvent[]
  sendMessage: (message: string) => void
  abortSendMessage: () => void
  isReceivingMessage: boolean
  receivingMessageStatus: string | null
  title: string
  className?: string
}

export const Conversation: FC<Props> = ({
  caseId,
  events,
  sendMessage,
  abortSendMessage,
  isReceivingMessage,
  receivingMessageStatus,
  className,
  title,
}) => {
  const t = useTranslation()
  const [selectedMediaUrl, setSelectedImage] = useState<string | null>(null)
  const [scrollUpSize, setScrollUpSize] = useState(0)

  const {
    setFalse: dismissMediaGallery,
    setTrue: openMediaGallery,
    state: showMediaGallery,
  } = useBooleanState(false)
  const [message, setMessage] = useState("")

  const onSendMessage = (message: string): void => {
    const isThirdMessage =
      events.filter((e) => e.eventType === "message" && e.type === "human")
        .length === 2

    setMessage("")
    sendMessage(message)

    if (isThirdMessage) {
      trackEvent("chat_engaged")
    }
  }

  const onSend = (): void => {
    onSendMessage(message)
  }

  const onChooseMultipleChoiceOption = (option: string): void => {
    onSendMessage(option)
  }

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const blurInput = useCallback(() => {
    inputRef.current?.blur()
  }, [])

  const {
    state: hasNewMessage,
    setFalse: clearNewMessage,
    setTrue: setNewMessage,
  } = useBooleanState(false)
  const {
    state: isAtTheBottom,
    setFalse: setIsNotAtTheBottom,
    setTrue: setIsAtTheBottom,
  } = useBooleanState(true)
  const {
    state: shouldAutoScroll,
    setFalse: disableAutoScroll,
    setTrue: enableAutoScroll,
  } = useBooleanState(true)
  const scrollRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const hasDoneInitialScroll = useRef(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollToBottom = useCallback(
    throttle((scrollToTopOfLatestMessage = true): void => {
      // When we are scrolling in response to new messages, we want to scroll so
      // the top of last message is as close as possible to the top of the vieweable
      // chat area. When we are scrolling in response to the down arrow button or
      // the initial navigation to the page, we want to scroll to the very
      // bottom of the chat container.
      if (!scrollRef.current) return

      const latestMessageElement = last(
        scrollRef.current.getElementsByClassName(messageContainerClass) || [],
      )

      if (latestMessageElement && scrollToTopOfLatestMessage) {
        latestMessageElement.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        })
      } else {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
        })
      }
    }, 500),
    [],
  )

  scrollRef.current?.addEventListener("scroll", () => {
    const scrollHeight = scrollRef.current?.scrollHeight ?? 0
    const clientHeight = scrollRef.current?.clientHeight ?? 0
    const scrollTop = scrollRef.current?.scrollTop ?? 0
    const pixelThresholdForBeingAtBottom = 100
    const isScrollAtTheBottom =
      Math.abs(scrollTop + clientHeight - scrollHeight) <
      pixelThresholdForBeingAtBottom

    const scrollUpMeasure = scrollHeight - (scrollTop + clientHeight)

    setScrollUpSize(scrollUpMeasure)

    if (isScrollAtTheBottom) {
      setIsAtTheBottom()
      enableAutoScroll()
      clearNewMessage()
    } else {
      setIsNotAtTheBottom()
      disableAutoScroll()
    }
  })

  const lastEvent = last(events)
  const lastEventContent =
    lastEvent?.eventType === "message" ? lastEvent.content : ""

  // After the user submits a message, we send a request to the backend then
  // optimistically insert both the human message and an empty AI message into the
  // cache. At that point, we want to scroll to the top of the empty AI message
  // (which is showing the status). Once the actual AI response shows up and its
  // content changes from a blank string to a full response, scroll so the top of
  // that message is as high as possible.
  useEffect(() => {
    if (!hasDoneInitialScroll.current) return

    const didHumanJustSubmitMsg =
      lastEvent &&
      lastEvent.eventType === "message" &&
      lastEvent.type === "ai" &&
      lastEvent.content.question === ""

    if (didHumanJustSubmitMsg || shouldAutoScroll) {
      scrollToBottom()
    }

    if (!isAtTheBottom && !didHumanJustSubmitMsg) {
      setNewMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEventContent])

  useEffect(() => {
    scrollToBottom(false)
    hasDoneInitialScroll.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffectOnMount(() => {
    inputRef.current?.focus()
  })

  const onTouchMove = (): void => {
    blurInput()
    disableAutoScroll()
  }

  const multimedia = events
    .flatMap((event) =>
      event.eventType === "message" ? getMediaFromMessage(event) : [],
    )
    .reduce((prev, curr) => {
      if (prev.includes(curr)) {
        return prev
      }
      return [...prev, curr]
    }, [] as MessageMedia[])

  const onOpenMediaGallery = (image: string): void => {
    openMediaGallery()
    setSelectedImage(image)
  }

  const filteredMultimedia = multimedia.filter(({url}) => !!url)

  const initialImageIndex = selectedMediaUrl
    ? filteredMultimedia
        .map((media) =>
          isMessageVideo(media) ? media.thumbnailUrl : media.url,
        )
        .indexOf(selectedMediaUrl)
    : 0

  return (
    <>
      {filteredMultimedia.length > 0 && (
        <MediaGallery
          show={showMediaGallery}
          title={title}
          onDismiss={dismissMediaGallery}
          media={filteredMultimedia}
          initialImageIndex={initialImageIndex}
        />
      )}
      <div
        role="main"
        aria-label={t("conversation.ariaLabel")}
        onTouchMove={blurInput}
        className={cx(
          css({
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            desktopDown: {
              backgroundColor: "background.brand.primary",
            },
          }),
          className,
        )}
      >
        <div
          onTouchMove={onTouchMove}
          className={cx(
            css({
              flex: 1,
              paddingX: 32,
              overflowY: "auto",
            }),
          )}
          ref={scrollRef}
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: 16,
              marginTop: 16,
              desktop: {
                paddingX: 8,
              },
            })}
            ref={contentRef}
          >
            <CopyLink />
            {events.map((event, index) => {
              if (event.eventType === "message") {
                const isMostRecent = index === events.length - 1

                const previouslyConfirmedCauseIds =
                  findLastMap(events.slice(0, index), (event) => {
                    if (event.eventType !== "message" || event.type !== "ai") {
                      return undefined
                    }
                    const confirmed = event.causes.filter(
                      isCauseOnMessageConfirmed,
                    )
                    return confirmed.map(({causeId}) => causeId)
                  }) ?? []

                return (
                  <Message
                    caseId={caseId}
                    message={event}
                    previouslyConfirmedCauseIds={previouslyConfirmedCauseIds}
                    isMostRecentMessage={isMostRecent}
                    isReceivingMessage={
                      event.id === OPTIMISTIC_AI_MESSAGE_ID &&
                      isReceivingMessage
                    }
                    receivingMessageStatus={receivingMessageStatus}
                    onSelectMedia={onOpenMediaGallery}
                    key={`message-${event.id}`}
                  />
                )
              } else if (event.eventType === "resolveEvent") {
                return (
                  <ResolveEvent
                    resolveEvent={event}
                    key={`resolveEvent-${event.id}`}
                  />
                )
              } else {
                return null
              }
            })}

            <ConversationActions
              scrollUpSize={scrollUpSize}
              scrollToBottom={scrollToBottom}
              hasNewMessage={hasNewMessage}
              events={events}
              onChooseMultipleChoiceOption={onChooseMultipleChoiceOption}
            />
          </div>
        </div>
        <div
          className={cx(
            css({
              flex: 0,
              height: "auto",
              zIndex: 10,
              paddingLeft: 19,
              paddingRight: 21,
              paddingBottom: 16,
              backgroundColor: "background.brand.primary",
              desktop: {
                position: "relative",
                paddingX: 32,
              },
            }),
            hstack({gap: 8, alignItems: "flex-end"}),
          )}
        >
          <ConversationInputField
            value={message}
            onFocus={() => {
              scrollToBottom(false)
            }}
            onChange={setMessage}
            onSend={onSend}
            onAbort={abortSendMessage}
            disabled={isReceivingMessage || message.length === 0}
            isReceiving={isReceivingMessage}
            inputRef={inputRef}
            className={css({
              width: "100%",
              backgroundColor: "white",
            })}
          />
        </div>
      </div>
    </>
  )
}
